import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../Buttons/Button";

export const OfferCollectionCard = ({ offer, onClickOffer }) => {
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <div
      className="flex bg-white shadow-md rounded-lg  flex-col overflow-hidden relative cursor-pointer"
      key={`offer-${offer.id}`}
      onClick={() => {
        navigate(`/${language}/offer/${offer.slug || offer.id}`);
        onClickOffer?.(offer);
      }}
    >
      <img
        src={offer.image}
        alt={offer.title}
        className="w-full object-contain  object-center"
        loading="lazy"
      />
      <div className="flex flex-row justify-between items-center p-4 py-2 bg-white/30  backdrop-blur-md absolute left-0 right-0 bottom-0">
        <h3 className="text-sm text-white font-normal line-clamp-1">
          {offer.title}
        </h3>
        <Button size="small" color="primary" className="py-1">
          {t("offers.view")}
        </Button>
      </div>
    </div>
  );
};
